







  import { Component, Prop, Vue } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'

  const products = namespace('products')

  @Component({
    components: {
      OneCmsProductFetcherComponent: () => import('~/components/organisms/grid/shared/OneCmsProductFetcherComponent.vue'),
      OneCmsDynamicComponent: () => import('~/components/organisms/grid/shared/OneCmsDynamicComponent.vue'),
    },
  })
  export default class OneCmsProductContainer extends Vue {
    name: string = 'OneCmsProductContainer.vue'

    @products.Action fetchProductWithPriceAndStock: any;

    @Prop({
      required: true,
      type: String,
    })
    readonly productId!: string

    @Prop({
      required: false,
      default: 'div',
      type: String,
    })
    readonly tag!: string

    @Prop({
      required: false,
      type: String,
    })
    readonly content!: string
  }
